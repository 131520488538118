<template>
	<form class="payments_popup-form payments_subscribe-form">
		<div class="form_group">
			<h4 class="total_amount">결제 금액 : {{ form.amount }}원</h4>
			<p>Modulus / 1개월 / 이용기간 만료 후 자동결제</p>
		</div>
		<hr />
		<h3>1. 주문 정보 입력</h3>
		<div class="form_group">
			<label for="form_orderer_name">주문자 명 *</label>
			<input
				id="form_orderer_name"
				type="text"
				v-model="form.ordererName"
				value="`${form.ordererName}`"
			/>
		</div>
		<div class="form_group">
			<label for="form_orderer_email">주문자 이메일 *</label>
			<input id="form_orderer_email" type="text" v-model="form.ordererEmail" />
		</div>
		<div class="form_group">
			<label for="form_orderer_phoneNum">주문자 전화번호 *</label>
			<input
				id="form_orderer_phoneNum"
				type="text"
				v-model="form.ordererPhoneNum"
				value="`${form.ordererPhoneNum}`"
			/>
		</div>
		<hr />
		<div class="form_group">
			<label for="form_user_name">이용자 성명 *</label>
			<input
				id="form_user_name"
				type="text"
				v-model="form.userName"
				value="`${form.userName}`"
			/>
		</div>
		<div class="form_group">
			<label for="form_user_age">이용자 나이 *</label>
			<input id="form_user_age" type="text" v-model="form.userAge" />
		</div>
		<div class="form_group">
			<label for="form_user_sex">이용자 성별</label>
			<input
				id="form_user_sex"
				type="text"
				v-model="form.userSex"
				value="`${form.userSex}`"
			/>
		</div>
		<div class="form_group">
			<label for="form_user_zipcode">이용자 우편번호</label>
			<input
				id="form_user_zipcode"
				type="text"
				v-model="form.userZipCode"
				value="`${form.userZipCode}`"
			/>
		</div>
		<div class="form_group">
			<label for="form_user_adress">이용자 주소 *</label>
			<input
				id="form_user_adress"
				type="text"
				v-model="form.userAdress"
				value="`${form.userAdress}`"
			/>
		</div>
		<div class="form_group">
			<label for="form_user_adress_detail">이용자 상세 주소 *</label>
			<input
				id="form_user_adress_detail"
				type="text"
				v-model="form.userAdressDetail"
				value="`${form.userAdressDetail}`"
			/>
		</div>
		<hr />
		<h3>2. 신용카드 정보 입력</h3>
		<div class="form_group">
			<label for="form_card_num">신용카드 번호 *</label>
			<input
				id="form_card_num"
				type="number"
				v-model="form.cardNum"
				value="`${form.cardNum}`"
			/>
			<p>신용카드 번호 숫자만 입력해주세요</p>
		</div>
		<div class="form_group">
			<label class="form_card_expiration">만료일 *</label>
			<div class="form_group-flex">
				<div>
					<select id="form_card_month" v-model="form.cardMonth">
						<option
							v-for="cardMonth in form.cardMonthList"
							:key="cardMonth.id"
							:value="cardMonth.value"
						>
							{{ cardMonth.value }}
						</option>
					</select>
					<label for="form_card_month">월</label>
				</div>
				<div>
					<select id="form_card_year" v-model="form.cardYear">
						<option
							v-for="cardYear in form.cardYearList"
							:key="cardYear.id"
							:value="cardYear.value"
						>
							{{ cardYear.value }}
						</option>
					</select>
					<label for="form_card_year">년</label>
				</div>
			</div>
		</div>
		<div class="form_group">
			<label for="form_card_password">비밀번호 앞 2자리 *</label>
			<input
				id="form_card_password"
				type="number"
				v-model="form.cardPassword"
				oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
				maxlength="2"
				max="99"
				min="00"
			/>
			<p>신용카드 비밀번호의 앞 2자리를 입력해주세요</p>
		</div>
		<div class="form_group">
			<label for="form_birth_registration"
				>생년월일 6자리 또는 사업자등록번호 *</label
			>
			<input
				id="form_birth_registration"
				type="number"
				v-model="form.birthOrRegistration"
				oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
				value="`${form.birthOrRegistration}`"
				maxlength="10"
				minlength="6"
			/>
			<p>
				개인카드는 생년월일 6자리, 법인카드는 사업자등록번호 10자리를
				입력해주세요.
			</p>
		</div>
		<hr />
		<div class="form_group-flex">
			<input
				class="form-checkbox"
				type="checkbox"
				v-model="form.agreeChecked"
				value="`${form.agreeChecked}`"
			/>
			<p>결제조건을 확인하였으며 구매 진행에 동의합니다.</p>
		</div>
		<input
			class="form-submit"
			type="button"
			value="결제 및 구독하기"
			@click="serverAuth()"
		/>
	</form>
</template>

<script>
export default {
	name: 'FctsMinjeongPaymentsSubscribe',
	data() {
		return {
			form: {
				amount: 54600,
				ordererName: '',
				ordererEmail: '',
				ordererPhoneNum: '',
				userName: '',
				userAge: '',
				userSex: '',
				userZipCode: '',
				userAdress: '',
				userAdressDetail: '',
				cardNum: '',
				cardMonth: '08',
				cardMonthList: [
					{
						id: 1,
						value: '01',
					},
					{
						id: 2,
						value: '02',
					},
					{
						id: 3,
						value: '03',
					},
					{
						id: 4,
						value: '04',
					},
					{
						id: 5,
						value: '05',
					},
					{
						id: 6,
						value: '06',
					},
					{
						id: 7,
						value: '07',
					},
					{
						id: 8,
						value: '08',
					},
					{
						id: 9,
						value: '09',
					},
					{
						id: 10,
						value: '10',
					},
					{
						id: 11,
						value: '11',
					},
					{
						id: 12,
						value: '12',
					},
				],
				cardYear: '2024',
				cardYearList: [
					{
						id: 1,
						value: '2024',
					},
					{
						id: 2,
						value: '2025',
					},
					{
						id: 3,
						value: '2026',
					},
					{
						id: 4,
						value: '2027',
					},
					{
						id: 5,
						value: '2028',
					},
					{
						id: 6,
						value: '2029',
					},
					{
						id: 7,
						value: '2030',
					},
				],
				cardPassword: '',
				birthOrRegistration: '',
				agreeChecked: false,
			},
		};
	},

	// mounted() {
	// },

	methods: {
		bindNumber(event) {
			this.form.cardPassword = event.target.value;
		},
	},
	watch: {
		form: {
			cardExpirationMonth(newValue, oldValue) {
				console.log(newValue, oldValue);
			},
			deep: true,
		},
	},
};
</script>

<style></style>
